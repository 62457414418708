/*** Project fonts ***/

/* fonts START */

@include css3-font-face('Hurme-Geometric-Sans-No3-Regular', 'Hurme-Geometric-Sans-No3-Regular');
@include css3-font-face('Hurme-Geometric-Sans-No3-Bold', 'Hurme-Geometric-Sans-No3-Bold');
@include css3-font-face('Hurme-Geometric-Sans-No3-Light', 'Hurme-Geometric-Sans-No3-Light');

@include css3-font-face('ChronicleDisplay-Bold', 'ChronicleDisplay-Bold');

/* fonts END */
