/*** Project subpages ***/

/* subpage name START */

.subpage {
    &-subtitle{
        padding-left: 10px;
    }

    &-circle{
        &-item{
            margin-left: auto;
            margin-right: auto;
            max-width: 225px;

            &-image{
                margin-bottom: 30px;
                border: 10px solid $color-light-gray;
                @include css3-border-radius(50%);
                overflow: hidden;
            }
        }
    }

    &-full-width-slider{
        a{
            display: block;

            // desktop
            @media (min-width: $screen-lg-min) {
                @include css3-opacity(0.5);

                &.active,
                &:hover,
                &:focus {
                    @include css3-opacity(1);
                }
            }
        }
    }

    &-contact{
        h4{
            margin-left: 10px;
            margin-bottom: 15px;
            text-transform: uppercase;
        }

        &-form{
            border-bottom: 1px solid $color-light-gray5;
        }
    }

    &-404{
        &-big{
            p{
                color: $color-dark-blue2;
                font-family: $project-default-font-family-1-BOLD;
                font-size: 110px;
                line-height: 150px;
                text-transform: uppercase;
            }
        }
    }

    &-flex-btn{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .btn{
            margin-bottom: 15px;

            &:first-child{
                margin-right: 15px;
            }
        }
    }
}

/* subpage name END */
