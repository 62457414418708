/*** Admin modifications ***/

/* admin START */

.top-margin {
	margin-top: 50px;
}

.admin{
	&.edit{
		.header{
			margin-bottom: 150px;
		}
	}
}

/* admin END */
