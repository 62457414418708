/*** Project typography ***/

/* typography START */

// main
.main {
	color: $project-default-color;
	font-family: $project-default-font-family;
	font-size: $project-default-font-size;
	line-height: $project-default-line-height;

	// anchor
	a {
		@include project-default-transition();
		color: $project-link-color;
		font-family: $project-link-font-family;
		text-decoration: none;

		// desktop
		@media (min-width: $screen-lg-min) {
			&:hover,
			&:focus {
				@include css3-opacity($project-link-opacity-HOVER);
				color: $project-link-color-HOVER;
				outline: 0;
				text-decoration: none;
			}
		}
	}

	// strong
	strong,
	.strong {
		font-family: $project-strong-font-family;
	}

	// em
	em,
	.em {
		font-family: $project-em-font-family;
	}

	// em + strong
	em strong,
	strong em,
	.em-strong {
		font-family: $project-em-strong-font-family;
	}

	// small
	small,
	.small {
		font-size: $project-small-font-size;
	}

	// highlight
	.highlight {
		color: $project-highlight-color;
		font-family: $project-highlight-font-family;
	}
}

// texts
.texts {

	*{
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	img{
		@extend .img-responsive;
	}

	// anchor
	a {}

	// headings
	h1, h2, h3, h4, h5, h6,
	.h1, .h2, .h3, .h4, .h5, .h6 {
		margin: 0;
		padding-bottom: 0;
		padding-top: 0;
		letter-spacing: 1px;
	}

	h1,
	.h1 {
		margin-bottom: $project-h1-margin;
        text-transform: uppercase;

		&,
		a {
			color: $project-h1-color;
			font-family: $project-h1-font-family;
			font-size: $project-h1-font-size;
			line-height: $project-h1-line-height;
		}

		a{
			// desktop
			@media (min-width: $screen-lg-min) {
				&:hover,
				&:focus{
					color: $project-h1-color;
				}
			}
		}
	}

	h2,
	.h2 {
		margin-bottom: $project-h2-margin;
        text-transform: uppercase;

		&,
		a {
			color: $project-h2-color;
			font-family: $project-h2-font-family;
			font-size: $project-h2-font-size;
			line-height: $project-h2-line-height;
		}

		a{
			// desktop
			@media (min-width: $screen-lg-min) {
				&:hover,
				&:focus{
					color: $project-h2-color;
				}
			}
		}
	}

	h3,
	.h3 {
		margin-bottom: $project-h3-margin;
        text-transform: uppercase;

		&,
		a {
			color: $project-h3-color;
			font-family: $project-h3-font-family;
			font-size: $project-h3-font-size;
			line-height: $project-h3-line-height;
		}

		a{
			// desktop
			@media (min-width: $screen-lg-min) {
				&:hover,
				&:focus{
					color: $project-h3-color;
				}
			}
		}
	}

	h4,
	.h4 {
		margin-bottom: $project-h4-margin;

		&,
		a {
			color: $project-h4-color;
			font-family: $project-h4-font-family;
			font-size: $project-h4-font-size;
			line-height: $project-h4-line-height;
		}

		a{
			// desktop
			@media (min-width: $screen-lg-min) {
				&:hover,
				&:focus{
					color: $project-h4-color;
				}
			}
		}
	}

	h5,
	.h5 {
		margin-bottom: $project-h5-margin;

		&,
		a {
			color: $project-h5-color;
			font-family: $project-h5-font-family;
			font-size: $project-h5-font-size;
			line-height: $project-h5-line-height;
		}

		a{
			// desktop
			@media (min-width: $screen-lg-min) {
				&:hover,
				&:focus{
					color: $project-h5-color;
				}
			}
		}
	}

	h6,
	.h6 {
		margin-bottom: $project-h6-margin;

		&,
		a {
			color: $project-h6-color;
			font-family: $project-h6-font-family;
			font-size: $project-h6-font-size;
			line-height: $project-h6-line-height;
            text-transform: uppercase;
		}

		a{
			// desktop
			@media (min-width: $screen-lg-min) {
				&:hover,
				&:focus{
					color: $project-h6-color;
				}
			}
		}
	}

	// paragraph
	p {
		color: $project-p-color;
		font-family: $project-p-font-family;
		font-size: $project-p-font-size;
		line-height: $project-p-line-height;
		margin: 0 0 $project-p-margin 0;
	}

	// ul / ol
	ul,
	ol {
		margin: 0 0 $project-list-margin 0;
		padding: 0 0 0 $project-list-padding;

		li {
			color: $project-list-color;
			font-family: $project-list-font-family;
			font-size: $project-list-font-size;
			line-height: $project-list-line-height;
			list-style: none;

			// remove this if you don't need skinned "list-style-type"
			&:before {
				display: inline-block;
				margin-left: -$project-list-padding;
				padding-right: $project-list-padding-list-style-type;
				text-align: right;
				vertical-align: top;
				width: $project-list-padding;
			}

			// remove disances
			> h1,
			> .h1,
			> h2,
			> .h2,
			> h3,
			> .h3,
			> h4,
			> .h4,
			> h5,
			> .h5,
			> h6,
			> .h6,
			> p,
			> ul,
			> ol {
				&:first-child {
					margin-top: -( $project-list-line-height );
				}
			}
		}
	}

		// remove this if you don't need skinned "list-style-type"
		ul {

			> li {

				&:before {
					color: $project-ul-style-type-color;
					content: $project-ul-style-type-content;
					font-family: $project-ul-style-type-font-family;
					font-size: $project-ul-style-type-font-size;
				}
			}
		}

		// remove this if you don't need skinned "list-style-type"
		ol {
			counter-reset: li;

			> li {
				counter-increment: li;

				&:before {
					color: $project-ol-style-type-color;
					content: $project-ol-style-type-content;
					font-family: $project-ol-style-type-font-family;
					font-size: $project-ol-style-type-font-size;
				}
			}
		}

	// dl
	dl {
		margin-bottom: $project-dl-margin;

		dt {
			color: $project-dt-color;
			font-family: $project-dt-font-family;
			font-weight: bold;
			font-size: $project-dt-font-size;
			line-height: $project-dt-line-height;
		}

		dd {
			color: $project-dd-color;
			font-family: $project-dd-font-family;
			font-weight: normal;
			font-size: $project-dd-font-size;
			line-height: $project-dd-line-height;
		}
	}

	// blockquote
	blockquote,
	.blockquote {
		border-color: $project-blockquote-border-color;
		border-width: $project-blockquote-border-width;
		margin-bottom: $project-blockquote-margin;
		padding: $project-blockquote-padding;

		* {
			color: $project-blockquote-color;
			font-family: $project-blockquote-font-family;
			font-size: $project-blockquote-font-size;
			line-height: $project-blockquote-line-height;
		}
	}

	// strong
	strong,
	.strong {}

	// em
	em,
	.em {}

	// highlight
	.highlight {
		font-size: $project-highlight-font-size;
		line-height: $project-highlight-line-height;
	}
}


// text aligns
.text-right {
	@include list-right();
}

.text-center {
	@include list-center();
}

.text-justify-center {
	@include list-center();
	text-align: justify;
	text-align-last: center;
	-moz-text-align-last: center;
}

	// mobile
	@media (max-width: $screen-xs-max) {
		.xs-text-left {
			text-align: left;
		}

		.xs-text-right {
			@include list-right();
			text-align: right;
		}

		.xs-text-center {
			@include list-center();
			text-align: center;
		}

		.xs-text-justify {
			text-align: justify;
		}

		.xs-text-justify-center {
			@include list-center();
			text-align: justify;
			text-align-last: center;
			-moz-text-align-last: center;
		}
	}

	// tablet vertical
	@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
		.sm-text-left {
			text-align: left;
		}

		.sm-text-right {
			@include list-right();
			text-align: right;
		}

		.sm-text-center {
			@include list-center();
			text-align: center;
		}

		.sm-text-justify {
			text-align: justify;
		}

		.sm-text-justify-center {
			@include list-center();
			text-align: justify;
			text-align-last: center;
			-moz-text-align-last: center;
		}
	}

	// tablet horizontal
	@media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
		.md-text-left {
			text-align: left;
		}

		.md-text-right {
			@include list-right();
			text-align: right;
		}

		.md-text-center {
			@include list-center();
			text-align: center;
		}

		.md-text-justify {
			text-align: justify;
		}

		.md-text-justify-center {
			@include list-center();
			text-align: justify;
			text-align-last: center;
			-moz-text-align-last: center;
		}
	}

	// desktop
	@media (min-width: $screen-lg-min) {
		.dt-text-left {
			text-align: left;
		}

		.dt-text-right {
			@include list-right();
			text-align: right;
		}

		.dt-text-center {
			@include list-center();
			text-align: center;
		}

		.dt-text-justify {
			text-align: justify;
		}

		.dt-text-justify-center {
			@include list-center();
			text-align: justify;
			text-align-last: center;
			-moz-text-align-last: center;
		}
	}

	// laptop
	@media (min-width: $screen-lg-min) and (max-width: $screen-lg-max) {
		.lg-text-left {
			text-align: left;
		}

		.lg-text-right {
			@include list-right();
			text-align: right;
		}

		.lg-text-center {
			@include list-center();
			text-align: center;
		}

		.lg-text-justify {
			text-align: justify;
		}

		.lg-text-justify-center {
			@include list-center();
			text-align: justify;
			text-align-last: center;
			-moz-text-align-last: center;
		}
	}

	// HD
	@media (min-width: $screen-xl-min) {
		.xl-text-left {
			text-align: left;
		}

		.xl-text-right {
			@include list-right();
			text-align: right;
		}

		.xl-text-center {
			@include list-center();
			text-align: center;
		}

		.xl-text-justify {
			text-align: justify;
		}

		.xl-text-justify-center {
			@include list-center();
			text-align: justify;
			text-align-last: center;
			-moz-text-align-last: center;
		}
	}

/* typography END */
