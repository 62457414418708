/*** Project paddings ***/

/* paddings START */

/* no paddings helpers */
.no-padding {
	padding-left: 0;
	padding-right: 0;
}

.no-padding-left {
	padding-left: 0;
}

.no-padding-right {
	padding-right: 0;
}

	// mobile
	@media (max-width: $screen-xs-max) {

		.xs-no-padding {
			padding-left: 0;
			padding-right: 0;
		}

		.xs-no-padding-left {
			padding-left: 0;
		}

		.xs-no-padding-right {
			padding-right: 0;
		}
	}

	// tablet vertical
	@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {

		.sm-no-padding {
			padding-left: 0;
			padding-right: 0;
		}

		.sm-no-padding-left {
			padding-left: 0;
		}

		.sm-no-padding-right {
			padding-right: 0;
		}
	}

	// tablet horizontal
	@media (min-width: $screen-md-min) and (max-width: $screen-md-max) {

		.md-no-padding {
			padding-left: 0;
			padding-right: 0;
		}

		.md-no-padding-left {
			padding-left: 0;
		}

		.md-no-padding-right {
			padding-right: 0;
		}
	}

	// desktop
	@media (min-width: $screen-lg-min) {

		.dt-no-padding {
			padding-left: 0;
			padding-right: 0;
		}

		.dt-no-padding-left {
			padding-left: 0;
		}

		.dt-no-padding-right {
			padding-right: 0;
		}
	}

	// laptop
	@media (min-width: $screen-lg-min) and (max-width: $screen-lg-max) {

		.lg-no-padding {
			padding-left: 0;
			padding-right: 0;
		}

		.lg-no-padding-left {
			padding-left: 0;
		}

		.lg-no-padding-right {
			padding-right: 0;
		}
	}

	// HD
	@media (min-width: $screen-xl-min) {

		.xl-no-padding {
			padding-left: 0;
			padding-right: 0;
		}

		.xl-no-padding-left {
			padding-left: 0;
		}

		.xl-no-padding-right {
			padding-right: 0;
		}
	}


/* vertical paddings helpers */
.padding {

	// padding bottom
	&-bottom {

		// default
		&-default {
			padding-bottom: $project-default-margin-bottom;
		}

		// mobile
		@media (max-width: $screen-xs-max) {
			padding-bottom: $project-vertical-padding-MOBILE;
		}

		// tablet
		@media (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
			padding-bottom: $project-vertical-padding-TABLET;
		}

		// laptop
		@media (min-width: $screen-lg-min) and (max-width: $screen-lg-max) {
			padding-bottom: $project-vertical-padding-LAPTOP;
		}

		// HD
		@media (min-width: $screen-xl-min) {
			padding-bottom: $project-vertical-padding-HD;
		}

			// padding bottom for texts
			&-texts {

				// mobile
				@media (max-width: $screen-xs-max) {
					padding-bottom: $project-vertical-padding-MOBILE - $project-default-margin-bottom;
				}

				// tablet
				@media (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
					padding-bottom: $project-vertical-padding-TABLET - $project-default-margin-bottom;
				}

				// laptop
				@media (min-width: $screen-lg-min) and (max-width: $screen-lg-max) {
					padding-bottom: $project-vertical-padding-LAPTOP - $project-default-margin-bottom;
				}

				// HD
				@media (min-width: $screen-xl-min) {
					padding-bottom: $project-vertical-padding-HD - $project-default-margin-bottom;
				}
			}

		// padding bottom small
		&-sm {

			// mobile
			@media (max-width: $screen-xs-max) {
				padding-bottom: $project-vertical-padding-small-MOBILE;
			}

			// tablet
			@media (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
				padding-bottom: $project-vertical-padding-small-TABLET;
			}

			// laptop
			@media (min-width: $screen-lg-min) and (max-width: $screen-lg-max) {
				padding-bottom: $project-vertical-padding-small-LAPTOP;
			}

			// HD
			@media (min-width: $screen-xl-min) {
				padding-bottom: $project-vertical-padding-small-HD;
			}

				// padding bottom for texts
				&-texts {

					// mobile
					@media (max-width: $screen-xs-max) {
						padding-bottom: $project-vertical-padding-small-MOBILE - $project-default-margin-bottom;
					}

					// tablet
					@media (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
						padding-bottom: $project-vertical-padding-small-TABLET - $project-default-margin-bottom;
					}

					// laptop
					@media (min-width: $screen-lg-min) and (max-width: $screen-lg-max) {
						padding-bottom: $project-vertical-padding-small-LAPTOP - $project-default-margin-bottom;
					}

					// HD
					@media (min-width: $screen-xl-min) {
						padding-bottom: $project-vertical-padding-small-HD - $project-default-margin-bottom;
					}
				}
		}

		// padding bottom large
		&-lg {

			// mobile
			@media (max-width: $screen-xs-max) {
				padding-bottom: $project-vertical-padding-large-MOBILE;
			}

			// tablet
			@media (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
				padding-bottom: $project-vertical-padding-large-TABLET;
			}

			// laptop
			@media (min-width: $screen-lg-min) and (max-width: $screen-lg-max) {
				padding-bottom: $project-vertical-padding-large-LAPTOP;
			}

			// HD
			@media (min-width: $screen-xl-min) {
				padding-bottom: $project-vertical-padding-large-HD;
			}

				// padding bottom for texts
				&-texts {

					// mobile
					@media (max-width: $screen-xs-max) {
						padding-bottom: $project-vertical-padding-large-MOBILE - $project-default-margin-bottom;
					}

					// tablet
					@media (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
						padding-bottom: $project-vertical-padding-large-TABLET - $project-default-margin-bottom;
					}

					// laptop
					@media (min-width: $screen-lg-min) and (max-width: $screen-lg-max) {
						padding-bottom: $project-vertical-padding-large-LAPTOP - $project-default-margin-bottom;
					}

					// HD
					@media (min-width: $screen-xl-min) {
						padding-bottom: $project-vertical-padding-large-HD - $project-default-margin-bottom;
					}
				}
		}
	}

	// padding top
	&-top {

		// default
		&-default {
			padding-top: $project-default-margin-bottom;
		}

		// mobile
		@media (max-width: $screen-xs-max) {
			padding-top: $project-vertical-padding-MOBILE;
		}

		// tablet
		@media (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
			padding-top: $project-vertical-padding-TABLET;
		}

		// laptop
		@media (min-width: $screen-lg-min) and (max-width: $screen-lg-max) {
			padding-top: $project-vertical-padding-LAPTOP;
		}

		// HD
		@media (min-width: $screen-xl-min) {
			padding-top: $project-vertical-padding-HD;
		}

		// padding top small
		&-sm {

			// mobile
			@media (max-width: $screen-xs-max) {
				padding-top: $project-vertical-padding-small-MOBILE;
			}

			// tablet
			@media (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
				padding-top: $project-vertical-padding-small-TABLET;
			}

			// laptop
			@media (min-width: $screen-lg-min) and (max-width: $screen-lg-max) {
				padding-top: $project-vertical-padding-small-LAPTOP;
			}

			// HD
			@media (min-width: $screen-xl-min) {
				padding-top: $project-vertical-padding-small-HD;
			}
		}

		// padding top large
		&-lg {

			// mobile
			@media (max-width: $screen-xs-max) {
				padding-top: $project-vertical-padding-large-MOBILE;
			}

			// tablet
			@media (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
				padding-top: $project-vertical-padding-large-TABLET;
			}

			// laptop
			@media (min-width: $screen-lg-min) and (max-width: $screen-lg-max) {
				padding-top: $project-vertical-padding-large-LAPTOP;
			}

			// HD
			@media (min-width: $screen-xl-min) {
				padding-top: $project-vertical-padding-large-HD;
			}
		}
	}
}

/* paddings END */
