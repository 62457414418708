/*** Project fake position ***/
// HTML: <div class="fake-position"><div id="id-of-the-target"></div></div>

/* fake position START */

.fake-position {

	&,
	div {
		border: 0;
		display: block;
		font-size: 0;
		height: 0;
		line-height: 0;
		margin: 0;
		padding: 0;
	}

	// desktop
	@media (min-width: $screen-lg-min) {
		position: relative;

		div {
			left: 0;
			position: absolute;
			right: 0;
			top: $project-fake-position-top;
		}
	}

	// mobile + tablet
	@media (max-width: $screen-md-max) {
		position: relative;

		div {
			left: 0;
			position: absolute;
			right: 0;
			top: -30px;
		}
	}
}

/* fake position END */
