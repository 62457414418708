/*** Project CSS - 2016 ***/
@charset "utf-8";

/* Import Bootstrap elements START */

// Core variables and mixins
@import "bootstrap/variables";
@import "bootstrap/mixins";

// Reset and dependencies
@import "bootstrap/normalize";
@import "bootstrap/print";
//@import "bootstrap/glyphicons";

// Core CSS
@import "bootstrap/scaffolding";
@import "bootstrap/type";
@import "bootstrap/code";
@import "bootstrap/grid";
@import "bootstrap/tables";
@import "bootstrap/forms";
@import "bootstrap/buttons";

// Components
@import "bootstrap/component-animations";
//@import "bootstrap/dropdowns";
//@import "bootstrap/button-groups";
//@import "bootstrap/input-groups";
@import "bootstrap/navs";
//@import "bootstrap/navbar";
//@import "bootstrap/breadcrumbs";
@import "bootstrap/pagination";
//@import "bootstrap/pager";
//@import "bootstrap/labels";
//@import "bootstrap/badges";
//@import "bootstrap/jumbotron";
//@import "bootstrap/thumbnails";
@import "bootstrap/alerts";
//@import "bootstrap/progress-bars";
//@import "bootstrap/media";
//@import "bootstrap/list-group";
//@import "bootstrap/panels";
@import "bootstrap/responsive-embed";
//@import "bootstrap/wells";
//@import "bootstrap/close";

// Components w/ JavaScript
//@import "bootstrap/modals";
//@import "bootstrap/tooltip";
//@import "bootstrap/popovers";
//@import "bootstrap/carousel";

// Utility classes
@import "bootstrap/utilities";
//@import "bootstrap/responsive-utilities";

/* Import Bootstrap elements END */

/* Import project elements START */

// Mixins
@import "project-mixins";
@import "project-mixins-css3";

// Variables
@import "project-variables";

// Fonts
@import "project-fonts";

// Icons
@import "project-icons";

// Typography
@import "project-typography";

// Tables
@import "project-tables";

// Alerts
@import "project-alerts";

// Forms
@import "project-forms";

// Checkbox + radio skin
//@import "project-checkbox-radio-skin";

// Buttons
@import "project-buttons";

// Fake position
@import "project-fake-position";

// Grid extra
@import "project-grid-extra";

// Margins
@import "project-margins";

// Paddings
@import "project-paddings";

// Positions
@import "project-positions";

// Pagination
@import "project-pagination";

// Cookie bar
@import "project-cookie-bar";

// BG image
@import "project-bgimage";

// Color generator
@import "project-color-generator";

// Mobile scrollbar
@import "project-mobile-scrollbar";

// Triangles
//@import "project-triangles";

// Embed responsive layer
@import "project-embed-responsive-layer";

// Embed responsive layer for youtube
//@import "project-embed-responsive-layer-youtube";

// Grayscale
//@import "project-grayscale";

// Nav tabs
@import "project-nav-tabs";

// Select box
@import "project-selectbox";

// Header
@import "project-header";

// Layout
@import "project-layout";

// Index
@import "project-index";

// Subpages
@import "project-subpages";

// Print
//@import "project-print";

// Admin
@import "project-admin";

/* Import project elements END */
