/*** Project layout ***/


/* html + body START */

html,
body {
/*
	// mobile
	@media (max-width: $screen-xs-max) {
		height: 100%;
		overflow-y: scroll;
		width: 100%;
		-webkit-overflow-scrolling: touch;
	}
*/
}

html {}

body {
    .fancybox-wrap{
        overflow: visible !important;
    }

    .fancybox-inner .fancybox-image {
        @include project-default-transition();
    }
}

/* html + body END */

/* main START */

.main {
	background: $color-white;
	min-width: 320px;
	overflow: hidden;
	position: relative;

    .vertical-middle{
        display: inline-block;
        line-height: normal;
        vertical-align: middle;
        width: 100%;
    }

    .bg-color-light-gray{
        .contents-box-item-info{
            background-color: $color-white;

            &-header{
                background-color: $color-white;
            }
        }
    }

    .newsletter,
    .subpage-filter{
        padding-top: 35px;
        padding-bottom: 35px;

        // tablet, desktop
        @media (min-width: $screen-sm-min) {
            min-height: $project-form-control-height + 70;
            line-height: $project-form-control-height;

            h2{
                display: inline-block;
                margin-bottom: 0;
                vertical-align: middle;
            }
        }
    }

    .newsletter {
        .alert p {
            font-size: $project-default-font-size;
        }

        input.form-control {
            font-size: $project-default-font-size + 2;
        }
    }

    .floating-box{
        &-wrap{
            display: none;
            width: 45px;
            left: 0;
            bottom: 0px;
            z-index: 10;
            position: fixed;
        }

        &-item{
            margin-bottom: 5px;

            &:last-child{
                margin-bottom: 0px;
            }

            .btn{
                padding: 0;

                .safari &{
                    line-height: 55px;
                }
            }
        }
    }
}

/* main END */

/* header START */

.header {
    .pos-static{
        // mobile + tablet
        @media (max-width: $screen-md-max) {
            position: static;
        }
    }
}

/* header END */

/* contents START */

.main .contents {
    &-box{
        &-wrap{
            border-bottom: 1px solid $color-gray5;
        }

        &-item{
            position: relative;
            overflow: hidden;

            // mobile
            @media (max-width: $screen-xs-max) {
                margin-left: auto;
                margin-right: auto;
                max-width: 360px;
            }

            &-image{
                // tablet + desktop
                @media (min-width: $screen-sm-min) {
                    padding-bottom: 70px;
                }
            }

            &-info{
                background-color: $color-light-gray;

                // tablet + desktop
                @media (min-width: $screen-sm-min) {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    left: 0;
                    top: 0;
                    z-index: 1;
                    @include css3-transition('transform', 0.5s);
                }

                // desktop
                @media (min-width: $screen-lg-min) {
                    @include css3-transform(translateY(270px));
                }

                // tablet horizontal
                @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
                    @include css3-transform(translateY(220px));
                }

                // tablet vertical
                @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
                    @include css3-transform(translateY(259px));
                }

                &.open{
                    @include css3-transform(translateY(0px));

                    .contents-box-item-info-header{
                        // desktop
                        @media (min-width: $screen-lg-min) {
                            @include css3-transform(translateY(270px));
                        }

                        // tablet horizontal
                        @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
                            @include css3-transform(translateY(220px));
                        }

                        // tablet vertical
                        @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
                            @include css3-transform(translateY(259px));
                        }
                    }
                }

                &-header{
                    width: 100%;
                    /*height: 71px;*/
                    line-height: 71px;
                    border-bottom: 1px solid $color-light-gray4;

                    // tablet + desktop
                    @media (min-width: $screen-sm-min) {
                        position: absolute;
                        /*top: 0;*/
                        bottom: calc(80% - 3px);
                        background-color: $color-light-gray;
                        @include css3-transition('transform', 0.5s);
                    }

                    &-title{
                        padding-left: 20px;

                        h4{
                            margin-bottom: 0;
                            color: $color-dark-blue;
                            font-size: 14px;
                            line-height: 15px;
                        }

                        p{
                            margin-bottom: 0;
                            font-size: 12px;
                            line-height: 15px;
                        }
                    }

                    &-btn{
                        a.btn{
                            height: 70px;
                            line-height: 70px;
                        }
                    }

                    .bordered-left{
                        border-left: 1px solid $color-light-gray4;
                    }
                }

                &-body{
                    padding: 20px 20px 0px 20px;
                    overflow: auto;

                    p{
                        margin-bottom: 0;
                    }
                }
            }

            &_small{
                .contents-box-item{
                    &-image{
                        // tablet + desktop
                        @media (min-width: $screen-sm-min) {
                            padding-bottom: 50px;
                        }
                    }

                    &-info{
                        // desktop
                        @media (min-width: $screen-lg-min) {
                            @include css3-transform(translateY(197px));
                        }

                        // tablet horizontal
                        @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
                            @include css3-transform(translateY(159px));
                        }

                        // tablet vertical
                        @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
                            @include css3-transform(translateY(165px));
                        }

                        &.open{
                            @include css3-transform(translateY(0px));

                            .contents-box-item-info-header{
                                // desktop
                                @media (min-width: $screen-lg-min) {
                                    @include css3-transform(translateY(197px));
                                }

                                // tablet horizontal
                                @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
                                    @include css3-transform(translateY(159px));
                                }

                                // tablet vertical
                                @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
                                    @include css3-transform(translateY(165px));
                                }
                            }
                        }

                        &-header{
                            height: 51px;
                            line-height: 51px;

                            &-title{
                                h4{
                                    font-size: $project-default-font-size - 2;
                                    line-height: $project-default-line-height - 2;
                                }

                                p{
                                    font-size: $project-default-font-size - 4;
                                    line-height: $project-default-line-height - 4;
                                }
                            }

                            &-btn{
                                .btn{
                                    height: 50px;
                                    line-height: 50px;
                                    font-size: $project-default-font-size - 2;
                                }
                            }
                        }

                        &-body{
                            padding: 10px;

                            p{
                                font-size: $project-default-font-size - 1;
                                line-height: $project-default-line-height - 2;
                            }
                        }
                    }
                }
            }

            &_hover{
                .contents-box-item{
                    &-info{
                        // desktop
                        @media (min-width: $screen-lg-min) {
                            &:hover,
                            &:focus {
                                @include css3-transform(translateY(0px));

                                .contents-box-item-info-header{
                                    @include css3-transform(translateY(270px));
                                }
                            }
                        }

                        .contents-box-item-info-header{
                            // mobile + tablet
                            @media (max-width: $screen-md-max) {
                                position: relative;
                                top: auto;
                            }
                        }

                        // mobile + tablet
                        @media (max-width: $screen-md-max) {
                            position: relative;
                            width: 100%;
                            height: auto;
                            left: auto;
                            top: auto;
                            @include css3-transform(translateY(0px));
                        }

                        &-body{
                            // desktop
                            @media (min-width: $screen-lg-min) {
                                padding: 10px 10px 0px 10px;
                            }

                            // mobile + tablet
                            @media (max-width: $screen-md-max) {
                                padding: 10px;
                            }
                        }
                    }

                    &-image{
                        // mobile + tablet
                        @media (max-width: $screen-md-max) {
                            padding-bottom: 0;
                        }
                    }
                }
            }

            &.contents-box-item_small.contents-box-item_hover{
                .contents-box-item-info{
                    // desktop
                    @media (min-width: $screen-lg-min) {
                        &:hover,
                        &:focus {
                            .contents-box-item-info-header{
                                @include css3-transform(translateY(197px));
                            }
                        }
                    }
                }
            }
        }
    }
}

	/* content START */

	.content {
        &-image{
            &-vertical{
                margin-left: auto;
                margin-right: auto;
                max-width: 300px;
            }
        }
    }

	/* content END */

	/* sidebar START */

	.sidebar {}

	/* sidebar END */

/* contents END */

/* footer START */

.footer {
    background-color: $color-gray3;
    padding: 50px 0 50px 0;

    // mobile
    @media (max-width: $screen-xs-max) {
        .col-xs-12{
            margin-bottom: 35px;

            &:last-child{
                margin-bottom: 0;
            }
        }
    }

    &-logo{
        margin-bottom: 20px;

        img{
            max-width: 44px;
            width: 100%;
            height: auto;
        }
    }

    &-community{
        a{
            margin-left: 5px;
            margin-right: 5px;
            font-size: $project-default-font-size + 2;

            &:first-child{
                margin-left: 0;
            }

            &:last-child{
                margin-right: 0;
            }
        }
    }

    p{
        margin-bottom: 0;
        color: $color-white;
        text-transform: uppercase;

        a{
            color: $color-white;

            // desktop
            @media (min-width: $screen-lg-min) {
                &:hover,
                &:focus {
                    color: $color-white;
                }
            }
        }
    }

    a{
        color: $color-white;

        // desktop
        @media (min-width: $screen-lg-min) {
            &:hover,
            &:focus {
                color: $color-white;
            }
        }
    }
}

/* footer END */
