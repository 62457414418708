/*** Project tables ***/

/* tables START */

@mixin table-th() {
	background-color: $project-th-background-color;
	border-color: $project-th-border-color !important;
	border-style: solid !important;
	border-bottom-width: $project-th-border-width;
	border-left-width: 0;
	border-right-width: $project-th-border-width;
	border-top-width: $project-th-border-width !important;
	color: $project-th-color;
	font-family: $project-th-font-family;
	font-weight: bold;
	font-size: $project-th-font-size;
	line-height: $project-th-line-height;
	padding: $project-th-padding;

	&:first-child {
		border-left-width: $project-th-border-width;
	}
}

	@mixin table-th-first-row() {

		&:first-child th:first-child{
			@include css3-border-radius-separate($project-table-border-radius, 0, 0, 0);

			// mobile only + tablet only
			@media (max-width: $screen-md-max) {
				@include css3-border-radius(0);
			}
		}

		&:first-child th:last-child{
			@include css3-border-radius-separate(0, $project-table-border-radius, 0, 0);

			// mobile only + tablet only
			@media (max-width: $screen-md-max) {
				@include css3-border-radius(0);
			}
		}
	}

@mixin table-td() {
	background-color: $project-td-background-color;
	border-color: $project-td-border-color;
	border-style: solid;
	border-width: 0 $project-td-border-width $project-td-border-width 0;
	color: $project-td-color;
	font-family: $project-td-font-family;
	font-size: $project-td-font-size;
	line-height: $project-td-line-height;
	padding: $project-td-padding;

	&:first-child {
		border-left-width: $project-td-border-width;
	}
}

	@mixin table-td-first-row() {

		&:first-child td:first-child {
			@include css3-border-radius-separate($project-table-border-radius, 0, 0, 0);

			// mobile only + tablet only
			@media (max-width: $screen-md-max) {
				@include css3-border-radius(0);
			}
		}

		&:first-child td:last-child {
			@include css3-border-radius-separate(0, $project-table-border-radius, 0, 0);

			// mobile only + tablet only
			@media (max-width: $screen-md-max) {
				@include css3-border-radius(0);
			}
		}
	}

	@mixin table-td-last-row() {

		&:last-child td:first-child {
			@include css3-border-radius-separate(0, 0, 0, $project-table-border-radius);

			// mobile only + tablet only
			@media (max-width: $screen-md-max) {
				@include css3-border-radius(0);
			}
		}

		&:last-child td:last-child {
			@include css3-border-radius-separate(0, 0, $project-table-border-radius, 0);

			// mobile only + tablet only
			@media (max-width: $screen-md-max) {
				@include css3-border-radius(0);
			}
		}
	}

// main
.main {

	// table
	table.table {
		border-collapse: inherit;
		margin: 0 0 $project-table-margin 0;

        h6,
        .h6{
            margin-bottom: 0 !important;
        }

		thead {

			tr {

				th,
				td {
					@include project-default-transition();
					@include table-th();
                    border-right-width: 0;
                    border-left-width: 0;
				}

				@include table-th-first-row();
				@include table-td-first-row();
			}
		}

		tbody {

			tr {

				th {
					@include project-default-transition();
					@include table-th();
				}

				@include table-th-first-row();

				td {
					@include project-default-transition();
                    @include table-td();
                    border-right: 0;
                    border-left: 0;
                }

                &:first-child{
                    td{
                        border-top: $project-td-border-width solid $project-td-border-color;
                    }
                }

				@include table-td-last-row();
			}
		}

		// table striped
		&.table-striped {

			tbody {

				tr:nth-of-type(2n+1) {

					td {
						background-color: $project-td-background-color-STRIPE;
					}
				}
			}
		}

		// table hover
		&.table-hover {

			thead {

				tr {

					// desktop
					@media (min-width: $screen-lg-min) {
						&:hover {
							th,
							td {
								background-color: $project-th-background-color-HOVER;
								color: $project-th-color-HOVER;
							}
						}
					}
				}
			}

			tbody {

				tr {

					// desktop
					@media (min-width: $screen-lg-min) {
						&:hover {
							th {
								background-color: $project-th-background-color-HOVER;
								color: $project-th-color-HOVER;
							}

							td {
								background-color: $project-td-background-color-HOVER;
								color: $project-td-color-HOVER;
							}
						}
					}
				}
			}
		}
	}

	// table responsive
	.table-responsive {

		// mobile
		@media (max-width: $screen-xs-max) {
			@include css3-border-radius(0);
			background-color: $project-table-responsive-background-color;
			border-color: $project-table-responsive-border-color;
			border-style: solid;
			border-width: $project-table-responsive-border-width;
			margin-bottom: $project-table-responsive-margin;
		}
	}
}

/* tables END */
