/*** Project icons ***/

/* Font Awesome START */

// import Font Awesome font from maxcdn
//@import 'http://maxcdn.bootstrapcdn.com/font-awesome/latest/css/font-awesome.min.css';
// !! Available in the icomoon library too - https://icomoon.io/app/#/select/library

/* Font Awesome END */

/* icomoon START */

// include icomoon icons font
@include css3-font-face(icomoon, icomoon);

// setup icomoon
@mixin icomoon() {
	font-family: 'icomoon';
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	line-height: 1;
	speak: none;
	text-transform: none;

	// Better Font Rendering
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icomoon,
[class^="icomoon-"],
[class*=" icomoon-"] {
	@include icomoon();
}

// setup icomoon classes
.icomoon-8-ways:before { content: "\e600"; } // &#xe600;
.icomoon-angle-left:before {
    content: "\e901";
}
.icomoon-angle-right:before {
    content: "\e902";
}
.icomoon-angle-up:before {
    content: "\e903";
}
.icomoon-facebook:before {
    content: "\e904";
}
.icomoon-google-plus:before {
    content: "\e905";
}
.icomoon-linkedin:before {
    content: "\e906";
}
.icomoon-youtube-play:before {
    content: "\e907";
}

/* icomoon END */
