/*** Project index page ***/

/* index START */

.index {
    &-intro{
        &-item{
            position: relative;
            overflow: hidden;

            // HD
            @media (min-width: $screen-xl-min) {
                height: 680px;
                line-height: 680px;
            }

            // laptop
            @media (min-width: $screen-lg-min) and (max-width: $screen-lg-max) {
                height: 480px;
                line-height: 480px;
            }

            // tablet
            @media (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
                padding-top: 150px;
                padding-bottom: 150px;
            }

            // mobile
            @media (max-width: $screen-xs-max) {
                padding-top: 50px;
                padding-bottom: 50px;
            }

            // desktop
            @media (min-width: $screen-lg-min) {
                &:hover,
                &:focus {
                    .index-intro-item-image-filter{
                        @include css3-opacity(0.7);
                    }

                    .index-intro-item-border{
                        @include css3-opacity(1);
                        @include css3-transform(scale(1));
                    }

                    .index-intro-item-text-collapsable{
                        max-height: 600px;
                    }
                }
            }

            &-image{
                &-slider{
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    line-height: normal;

                    .owl-item{
                        .bg-image.bg-image-4by3{
                            // mobile + tablet
                            @media (max-width: $screen-md-max) {
                                position: absolute;
                                top: 0;
                                right: 0;
                                bottom: 0;
                                left: 0;
                                padding-bottom: 0;
                            }
                        }
                    }

                    &.owl-theme{
                        .owl-dots{
                            position: absolute;
                            margin-top: 0 !important;
                            bottom: 15px;
                            width: 100%;
                            z-index: 3;
                        }
                    }
                }

                &-filter{
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    background-color: $color-gray4;
                    z-index: 1;
                    will-change: transform;

                    // desktop
                    @media (min-width: $screen-lg-min) {
                        @include css3-transition('opacity', 0.5s);
                        @include css3-opacity(0);
                    }

                    // mobile + tablet
                    @media (max-width: $screen-md-max) {
                        @include css3-opacity(0.7);
                    }
                }
            }

            &-border{
                // desktop
                @media (min-width: $screen-lg-min) {
                    position: absolute;
                    top: 5%;
                    left: 5%;
                    width: 90%;
                    height: 85%;
                    border-color: $color-light-gray;
                    border-style: solid;
                    border-width: 0 1px 1px 1px;
                    z-index: 2;
                    @include css3-transition('all', 0.5s);
                    @include css3-opacity(0);
                    @include css3-transform(scale(0.5));
                }

                // mobile + tablet
                @media (max-width: $screen-md-max) {
                    display: none;
                }

                &-number{
                    text-align: center;

                    p{
                        margin-top: -6px;
                        margin-bottom: 0;
                        font-family: $project-default-font-family-2-BOLD;
                        font-size: $project-default-font-size;
                        line-height: $project-default-font-size;
                        color: $color-light-gray;

                        &:before,
                        &:after{
                            display: block;
                            position: absolute;
                            top: 0;
                            width: 47%;
                            height: 1px;
                            background-color: $color-light-gray;
                            content: "";
                        }

                        &:before{
                            left: 0;
                        }

                        &:after{
                            right: 0;
                        }
                    }
                }
            }

            &-text{
                position: relative;
                z-index: 3;

                &-title{
                    p{
                        margin-bottom: 0;
                        font-family: $project-default-font-family-2-BOLD;
                        font-size: $project-default-font-size + 20;
                        line-height: $project-default-line-height + 20;
                        color: $color-white;
                        text-transform: uppercase;
                    }
                }

                &-collapsable{
                    display: block;

                    // desktop
                    @media (min-width: $screen-lg-min) {
                        @include css3-transition('max-height', 0.5s);
                        height: auto;
                        max-height: 0;
                        overflow: hidden;
                    }

                    p{
                        color: $color-white;
                    }

                    a{
                        font-size: $project-default-font-family-1-BOLD;
                        color: $color-white;
                        text-transform: uppercase;

                        // desktop
                        @media (min-width: $screen-lg-min) {
                            &:hover,
                            &:focus {
                                color: $color-white;
                            }
                        }
                    }
                }
            }
        }
    }

    &-slider{
        &-partners{
            &-item{
                img{
                    margin-left: auto;
                    margin-right: auto;
                    max-width: 120px;
                }
            }

            &.owl-theme{
                .owl-nav{
                    // desktop
                    @media (min-width: $screen-lg-min) {
                        margin-top: 0;
                    }

                    // mobile + tablet
                    @media (max-width: $screen-md-max) {
                        margin-top: 30px;
                    }

                    [class*="owl-"]{
                        margin-right: 0;
                        margin-bottom: 0;
                        margin-left: 0;
                        padding: 0;
                        color: $color-dark-blue;
                        font-family: $project-default-font-family-WEBSAFE-1;
                        font-size: 24px;
                        background: none;
                        @include css3-border-radius(0);

                        i{
                            vertical-align: middle;
                        }

                        // desktop
                        @media (min-width: $screen-lg-min) {
                            position: absolute;
                            top: 50%;
                            margin-top: -20px;
                            width: 40px;
                            height: 40px;
                            line-height: 37px;
                            @include project-default-transition();

                            &.owl-prev{
                                left: -60px;
                            }

                            &.owl-next{
                                right: -60px;
                            }

                            &:hover,
                            &:focus {
                                background-color: $color-dark-blue2;
                                color: $color-white;
                            }
                        }

                        // mobile + tablet
                        @media (max-width: $screen-md-max) {
                            margin-top: 0;
                            background-color: $color-dark-blue2;
                            color: $color-white;
                            width: 40px;
                            height: 40px;
                            line-height: 37px;

                            &.owl-prev{
                                margin-right: 15px;
                            }
                        }
                    }
                }
            }
        }
    }
}

/* index END */
