/*** Project margin ***/

/* margin START */

/* no margin helpers */
.no-margin {
	margin-left: 0;
	margin-right: 0;
}

.no-margin-left {
	margin-left: 0;
}

.no-margin-right {
	margin-right: 0;
}

.margin-top-30 {
	margin-top: 30px;
}

.margin-bottom-30 {
	margin-bottom: 30px;
}

	// mobile
	@media (max-width: $screen-xs-max) {

		.xs-no-margin {
			margin-left: 0;
			margin-right: 0;
		}

		.xs-no-margin-left {
			margin-left: 0;
		}

		.xs-no-margin-right {
			margin-right: 0;
		}
	}

	// tablet vertical
	@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {

		.sm-no-margin {
			margin-left: 0;
			margin-right: 0;
		}

		.sm-no-margin-left {
			margin-left: 0;
		}

		.sm-no-margin-right {
			margin-right: 0;
		}
	}

	// tablet horizontal
	@media (min-width: $screen-md-min) and (max-width: $screen-md-max) {

		.md-no-margin {
			margin-left: 0;
			margin-right: 0;
		}

		.md-no-margin-left {
			margin-left: 0;
		}

		.md-no-margin-right {
			margin-right: 0;
		}
	}

	// desktop
	@media (min-width: $screen-lg-min) {

		.dt-no-margin {
			margin-left: 0;
			margin-right: 0;
		}

		.dt-no-margin-left {
			margin-left: 0;
		}

		.dt-no-margin-right {
			margin-right: 0;
		}
	}

	// laptop
	@media (min-width: $screen-lg-min) and (max-width: $screen-lg-max) {

		.lg-no-margin {
			margin-left: 0;
			margin-right: 0;
		}

		.lg-no-margin-left {
			margin-left: 0;
		}

		.lg-no-margin-right {
			margin-right: 0;
		}
	}

	// HD
	@media (min-width: $screen-xl-min) {

		.xl-no-margin {
			margin-left: 0;
			margin-right: 0;
		}

		.xl-no-margin-left {
			margin-left: 0;
		}

		.xl-no-margin-right {
			margin-right: 0;
		}
	}


/* vertical margin helpers */
.margin {

	// margin bottom
	&-bottom {

		// default
		&-default {
			margin-bottom: $project-default-margin-bottom;
		}

		// mobile
		@media (max-width: $screen-xs-max) {
			margin-bottom: $project-vertical-margin-MOBILE;
		}

		// tablet
		@media (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
			margin-bottom: $project-vertical-margin-TABLET;
		}

		// laptop
		@media (min-width: $screen-lg-min) and (max-width: $screen-lg-max) {
			margin-bottom: $project-vertical-margin-LAPTOP;
		}

		// HD
		@media (min-width: $screen-xl-min) {
			margin-bottom: $project-vertical-margin-HD;
		}

			// margin bottom for texts
			&-texts {

				// mobile
				@media (max-width: $screen-xs-max) {
					margin-bottom: $project-vertical-margin-MOBILE - $project-default-margin-bottom;
				}

				// tablet
				@media (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
					margin-bottom: $project-vertical-margin-TABLET - $project-default-margin-bottom;
				}

				// laptop
				@media (min-width: $screen-lg-min) and (max-width: $screen-lg-max) {
					margin-bottom: $project-vertical-margin-LAPTOP - $project-default-margin-bottom;
				}

				// HD
				@media (min-width: $screen-xl-min) {
					margin-bottom: $project-vertical-margin-HD - $project-default-margin-bottom;
				}
			}

		// margin bottom small
		&-sm {

			// mobile
			@media (max-width: $screen-xs-max) {
				margin-bottom: $project-vertical-margin-small-MOBILE;
			}

			// tablet
			@media (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
				margin-bottom: $project-vertical-margin-small-TABLET;
			}

			// laptop
			@media (min-width: $screen-lg-min) and (max-width: $screen-lg-max) {
				margin-bottom: $project-vertical-margin-small-LAPTOP;
			}

			// HD
			@media (min-width: $screen-xl-min) {
				margin-bottom: $project-vertical-margin-small-HD;
			}

				// margin bottom for texts
				&-texts {

					// mobile
					@media (max-width: $screen-xs-max) {
						margin-bottom: $project-vertical-margin-small-MOBILE - $project-default-margin-bottom;
					}

					// tablet
					@media (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
						margin-bottom: $project-vertical-margin-small-TABLET - $project-default-margin-bottom;
					}

					// laptop
					@media (min-width: $screen-lg-min) and (max-width: $screen-lg-max) {
						margin-bottom: $project-vertical-margin-small-LAPTOP - $project-default-margin-bottom;
					}

					// HD
					@media (min-width: $screen-xl-min) {
						margin-bottom: $project-vertical-margin-small-HD - $project-default-margin-bottom;
					}
				}
		}

		// margin bottom large
		&-lg {

			// mobile
			@media (max-width: $screen-xs-max) {
				margin-bottom: $project-vertical-margin-large-MOBILE;
			}

			// tablet
			@media (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
				margin-bottom: $project-vertical-margin-large-TABLET;
			}

			// laptop
			@media (min-width: $screen-lg-min) and (max-width: $screen-lg-max) {
				margin-bottom: $project-vertical-margin-large-LAPTOP;
			}

			// HD
			@media (min-width: $screen-xl-min) {
				margin-bottom: $project-vertical-margin-large-HD;
			}

				// margin bottom for texts
				&-texts {

					// mobile
					@media (max-width: $screen-xs-max) {
						margin-bottom: $project-vertical-margin-large-MOBILE - $project-default-margin-bottom;
					}

					// tablet
					@media (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
						margin-bottom: $project-vertical-margin-large-TABLET - $project-default-margin-bottom;
					}

					// laptop
					@media (min-width: $screen-lg-min) and (max-width: $screen-lg-max) {
						margin-bottom: $project-vertical-margin-large-LAPTOP - $project-default-margin-bottom;
					}

					// HD
					@media (min-width: $screen-xl-min) {
						margin-bottom: $project-vertical-margin-large-HD - $project-default-margin-bottom;
					}
				}
		}
	}

	// margin top
	&-top {

		// default
		&-default {
			margin-top: $project-default-margin-bottom;
		}

		// mobile
		@media (max-width: $screen-xs-max) {
			margin-top: $project-vertical-margin-MOBILE;
		}

		// tablet
		@media (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
			margin-top: $project-vertical-margin-TABLET;
		}

		// laptop
		@media (min-width: $screen-lg-min) and (max-width: $screen-lg-max) {
			margin-top: $project-vertical-margin-LAPTOP;
		}

		// HD
		@media (min-width: $screen-xl-min) {
			margin-top: $project-vertical-margin-HD;
		}

		// margin top small
		&-sm {

			// mobile
			@media (max-width: $screen-xs-max) {
				margin-top: $project-vertical-margin-small-MOBILE;
			}

			// tablet
			@media (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
				margin-top: $project-vertical-margin-small-TABLET;
			}

			// laptop
			@media (min-width: $screen-lg-min) and (max-width: $screen-lg-max) {
				margin-top: $project-vertical-margin-small-LAPTOP;
			}

			// HD
			@media (min-width: $screen-xl-min) {
				margin-top: $project-vertical-margin-small-HD;
			}
		}

		// margin top large
		&-lg {

			// mobile
			@media (max-width: $screen-xs-max) {
				margin-top: $project-vertical-margin-large-MOBILE;
			}

			// tablet
			@media (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
				margin-top: $project-vertical-margin-large-TABLET;
			}

			// laptop
			@media (min-width: $screen-lg-min) and (max-width: $screen-lg-max) {
				margin-top: $project-vertical-margin-large-LAPTOP;
			}

			// HD
			@media (min-width: $screen-xl-min) {
				margin-top: $project-vertical-margin-large-HD;
			}
		}
	}
}

/* margin END */
