/*** Project nav tabs ***/

/* nav tabs START */

// main
.main {
	ul.nav-tabs{
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		border-left: $projetct-nav-tabs-border-left-width solid $projetct-nav-tabs-border-left-color;
		border-right: $projetct-nav-tabs-border-right-width solid $projetct-nav-tabs-border-right-color;
		border-top: $projetct-nav-tabs-border-top-width solid $projetct-nav-tabs-border-top-color;
		border-bottom: $projetct-nav-tabs-border-bottom-width solid $projetct-nav-tabs-border-bottom-color;
		list-style: none;
		margin: 0;
		padding: 0;

		&:before,
		&:after{
			display: none;
		}

		> li{
			&:before{
				display: none;
			}

			> a{
      			margin: $projetct-nav-tabs-link-margin;
				padding: $projetct-nav-tabs-link-padding;
				font-family: $projetct-nav-tabs-link-font-family;
				font-size: $projetct-nav-tabs-link-font-size;
      			line-height: $projetct-nav-tabs-link-line-height;
				color: $projetct-nav-tabs-link-color;
				border-left: $projetct-nav-tabs-link-border-left-width solid $projetct-nav-tabs-link-border-left-color;
				border-right: $projetct-nav-tabs-link-border-right-width solid $projetct-nav-tabs-link-border-right-color;
				border-top: $projetct-nav-tabs-link-border-top-width solid $projetct-nav-tabs-link-border-top-color;
				border-bottom: $projetct-nav-tabs-link-border-bottom-width solid $projetct-nav-tabs-link-border-bottom-color;
				background-color: $projetct-nav-tabs-link-background-color;
				@include css3-border-radius($projetct-nav-tabs-link-border-radius);

				// desktop
				@media (min-width: $screen-lg-min) {
					&:hover,
					&:focus {
						color: $projetct-nav-tabs-link-color-HOVER;
						background-color: $projetct-nav-tabs-link-background-color-HOVER;
						border-top: $projetct-nav-tabs-link-border-top-width-HOVER solid $projetct-nav-tabs-link-border-top-color-HOVER;
						@include css3-border-radius($projetct-nav-tabs-link-border-radius-HOVER);
						@include css3-opacity($projetct-nav-tabs-link-color-HOVER-opacity);
					}
				}
			}

			&.active{
				a{
					color: $projetct-nav-tabs-link-color-ACTIVE;
					background-color: $projetct-nav-tabs-link-background-color-ACTIVE;
					border-top: $projetct-nav-tabs-link-border-top-width-ACTIVE solid $projetct-nav-tabs-link-border-top-color-ACTIVE;
					border-bottom: $projetct-nav-tabs-link-border-bottom-width-ACTIVE solid $projetct-nav-tabs-link-border-bottom-color-ACTIVE;
					border-left: $projetct-nav-tabs-link-border-left-width-ACTIVE solid $projetct-nav-tabs-link-border-left-color-ACTIVE;
					border-right: $projetct-nav-tabs-link-border-right-width-ACTIVE solid $projetct-nav-tabs-link-border-right-color-ACTIVE;
					@include css3-border-radius($projetct-nav-tabs-link-border-radius-ACTIVE);

					// desktop
					@media (min-width: $screen-lg-min) {
						&:hover,
						&:focus {
							color: $projetct-nav-tabs-link-color-ACTIVE-HOVER;
							background-color: $projetct-nav-tabs-link-background-color-ACTIVE-HOVER;
							border-top: $projetct-nav-tabs-link-border-top-width-ACTIVE-HOVER solid $projetct-nav-tabs-link-border-top-color-ACTIVE-HOVER;
							@include css3-opacity($projetct-nav-tabs-link-color-HOVER-opacity);
						}
					}
				}
			}
		}
	}
}

/* nav tabs END */