/*** Project header ***/

/* header START */

@mixin headerHeight(){
    // HD
    @media (min-width: $screen-xl-min) {
        height: $project-header-height-HD;
        line-height: $project-header-line-height-HD;
    }

    // laptop
    @media (min-width: $screen-lg-min) and (max-width: $screen-lg-max) {
        height: $project-header-height-LAPTOP;
        line-height: $project-header-line-height-LAPTOP;
    }

    // tablet
    @media (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
        height: $project-header-height-TABLET;
        line-height: $project-header-line-height-TABLET;
    }

    // mobile
    @media (max-width: $screen-xs-max) {
        height: $project-header-height-MOBILE;
        line-height: $project-header-line-height-MOBILE;
    }
}

// main
.main{
    @if $project-header-position == "fixed" {
        // desktop
        @media (min-width: $screen-lg-min) {
            padding-top: $project-header-height-LAPTOP;
        }
    }

    // heaader
    .header{
        @include css3-background-opacity($project-header-background-color, $project-header-background-color-opacity);
        @include headerHeight();
        position: $project-header-position;
        z-index: 5;

        @if $project-header-border-width != "0 0 0 0" {
            border-color: $project-header-border-color;
            border-style: $project-header-border-style;
            border-width: $project-header-border-width;
        }

        @if $project-header-position == "absolute" {
            left: $project-header-left;
            top: $project-header-top;
            width: 100%;
        }

        @if $project-header-position == "fixed" {
            left: $project-header-left;
            top: $project-header-top;
            width: 100%;

            // mobile + tablet
            @media (max-width: $screen-md-max) {
                position: relative;
            }
        }

        // header logo
        &-logo{
            a{
                display: inline-block;
                line-height: normal;
                width: 100%;
                vertical-align: middle;

                // HD
                @media (min-width: $screen-xl-min) {
                    max-width: $project-logo-width-HD;
                    max-height: $project-logo-height-HD;
                }

                // laptop
                @media (min-width: $screen-lg-min) and (max-width: $screen-lg-max) {
                    max-width: $project-logo-width-LAPTOP;
                    max-height: $project-logo-height-LAPTOP;
                }

                // tablet
                @media (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
                    max-width: $project-logo-width-TABLET;
                    max-height: $project-logo-height-TABLET;
                }

                // mobile
                @media (max-width: $screen-xs-max) {
                    max-width: $project-logo-width-MOBILE;
                    max-height: $project-logo-height-MOBILE;
                }

                svg{
                    vertical-align: middle;

                    // HD
                    @media (min-width: $screen-xl-min) {
                        max-width: $project-logo-width-HD;
                        max-height: $project-logo-height-HD;
                    }

                    // laptop
                    @media (min-width: $screen-lg-min) and (max-width: $screen-lg-max) {
                        max-width: $project-logo-width-LAPTOP;
                        max-height: $project-logo-height-LAPTOP;
                    }

                    // tablet
                    @media (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
                        max-width: $project-logo-width-TABLET;
                        max-height: $project-logo-height-TABLET;
                    }

                    // mobile
                    @media (max-width: $screen-xs-max) {
                        max-width: $project-logo-width-MOBILE;
                        max-height: $project-logo-height-MOBILE;
                    }
                }
            }
        }

        // header main menu
        &-main-menu{
            &-btn{
                position: relative;
                display: none;
                width: 30px;
                height: 30px;
                cursor: pointer;
                background: none;
                border: 0;
                box-shadow: none;
                outline:none;
                z-index: 1;
                vertical-align: middle;

                &:hover,
                &:focus{
                    border: 0;
                    outline:none;
                }

                // mobile + tablet
                @media (max-width: $screen-md-max) {
                    display: inline-block;
                }

                span {
                    display: none;
                    position: absolute;
                    top: 13px;
                    left: 0;
                    right: 0;
                    width: 30px;
                    height: 2px;
                    background-color: $project-main-menu-button-color;
                    @include project-default-transition;

                    // mobile + tablet
                    @media (max-width: $screen-md-max) {
                        display: block;
                    }

                    &:before,
                    &:after{
                        position: absolute;
                        display: block;
                        left: 0;
                        width: 100%;
                        height: 2px;
                        background-color: $project-main-menu-button-color;
                        content: "";
                    }

                    &:before {
                        top: -8px;
                        @include  css3-transition('all', '0.5s');
                    }

                    &:after {
                        bottom: -8px;
                        @include  css3-transition('all', '0.5s');
                    }
                    &:before,
                    &:after {
                        @include  css3-transition('all', '0.5s');
                    }
                }

                &.open{
                    span{
                        background-color: transparent;

                        &:before {
                            top: 0;
                            background-color: $project-main-menu-button-color-OPEN;
                            @include css3-rotate(45);
                        }
                        &:after {
                            bottom: 0;
                            background-color: $project-main-menu-button-color-OPEN;
                            @include css3-rotate(-45);
                        }
                    }
                }
            }

            nav{
                // desktop
                @media (min-width: $screen-lg-min) {
                    @include headerHeight();
                }

                // mobile + tablet
                @media (max-width: $screen-md-max) {
                    background-color: $project-main-menu-background-color;
                    max-width: $project-main-menu-width;
                    min-width: 320px;
                    padding: $project-main-menu-padding;
                    position: absolute;
                    right: 0px;
                    @include css3-transition('transform', 0.5s, ease-in-out);
                    @include css3-transform($project-header-main-menu-transform);
                    width: 100%;
                    z-index: 10;

                    // tablet
                    @media (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
                        top: $project-header-height-TABLET;
                    }

                    // mobile
                    @media (max-width: $screen-xs-max) {
                        top: $project-header-height-MOBILE;
                    }

                    &.open{
                        @include css3-transform($project-header-main-menu-transform-OPEN)
                    }
                }

                ul{
                    margin: 0;
                    padding: 0;

                    // desktop
                    @media (min-width: $screen-lg-min) {
                        @include headerHeight();
                    }

                    li{
                        color: $project-main-menu-item-color;
                        font-family: $project-main-menu-item-font-family;
                        font-size: $project-main-menu-item-font-size;

                        // desktop
                        @media (min-width: $screen-lg-min) {
                            @include headerHeight();
                        }

                        // desktop
                        @media (min-width: $screen-lg-min) {
                            display: inline-block;
                        }

                        // HD
                        @media (min-width: $screen-xl-min) {
                            margin: $project-main-menu-item-margin-HD;
                        }

                        // laptop
                        @media (min-width: $screen-lg-min) and (max-width: $screen-lg-max) {
                            margin: $project-main-menu-item-margin-LAPTOP;
                        }

                        // mobile + tablet
                        @media (max-width: $screen-md-max) {
                            display: block;
                        }

                        &:before{
                            color: $project-main-menu-item-before-color;
                            content: $project-main-menu-item-before-content;
                            display: $project-main-menu-item-before-display;
                            font-family: $project-main-menu-item-before-font-family;
                            font-size: $project-main-menu-item-before-font-size;
                            margin: $project-main-menu-item-before-margin;
                            padding: 0;
                            width: auto;

                            // mobile + tablet
                            @media (max-width: $screen-md-max) {
                                display: none;
                            }
                        }

                        &:first-child{
                            margin-left: 0;

                            &:before{
                                display: none;
                            }
                        }

                        &:last-child{
                            margin-right: 0;
                        }

                        a{
                            color: $project-main-menu-item-color;
                            display: inline-block;
                            font-family: $project-main-menu-item-font-family;
                            font-size: $project-main-menu-item-font-size;
                            height: auto;
                            line-height: normal;
                            position: relative;
                            text-transform: $project-main-menu-item-transform;
                            vertical-align: middle;

                            // mobile + tablet
                            @media (max-width: $screen-md-max) {
                                display: block;
                                line-height: 38px;
                            }

                            // desktop
                            @media (min-width: $screen-lg-min) {
                                &:hover,
                                &:focus {
                                    color: $project-main-menu-item-color-HOVER;
                                    text-decoration: $project-main-menu-item-decoration-HOVER;
                                    @include css3-opacity($project-main-menu-item-opacity-HOVER);
                                }
                            }

                            // Hover
                            @if $project-main-menu-item-decoration-HOVER == "none"{
                                &:before{
                                    background-color: $project-main-menu-item-color;
                                    bottom: 0;
                                    content: "";
                                    display: block;
                                    height: 1px;
                                    left: 50%;
                                    position: absolute;
                                    @include project-default-transition();
                                    width: 0px;

                                    // mobile + tablet
                                    @media (max-width: $screen-md-max) {
                                        display: none;
                                    }
                                }

                                // desktop
                                @media (min-width: $screen-lg-min) {
                                    &:hover,
                                    &:focus {
                                        color: $project-main-menu-item-color;

                                        &:before{
                                            left: 0;
                                            width: 100%;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/* header END */
