/*** Project variables defaults ***/

/* variables START */

/* ---- setup DEFAULTS START */

// border radius
$project-default-border-radius:				0px;

// color

	// basic colors
	$color-black:							black;
	$color-blue:							blue;
	$color-dark-blue:						#121f3f;
	$color-dark-blue2:						#0c1b3e;
	$color-brown:							brown;
	$color-green:							#48874a;
	$color-dark-green:						darkgreen;
	$color-gray:							#ababab;
	$color-gray2:							#999999;
	$color-gray3:							#878786;
	$color-gray4:							#86878c;
	$color-gray5:							#bdbdbc;
	$color-gray6:							#676766;
	$color-light-gray:						#f1f1f1;
	$color-light-gray2:						#f8f8f8;
	$color-light-gray3:						#ebebeb;
	$color-light-gray4:						#c9c9c9;
	$color-light-gray5:						#dadada;
	$color-dark-gray:						darkgray;
    $color-dark-gray2:						#3d3f44;
	$color-red:								#b74b4b;
	$color-dark-red:						darkred;
	$color-orange:							#efad57;
	$color-dark-orange:						darkorange;
	$color-yellow:							yellow;
	$color-white:							white;

	// social colors
//	$color-bandcamp:						#4E9BAC;
//	$color-delicious:						#248CFF;
//	$color-facebook:						#3B5998;
//	$color-ficly:							#B51C2C;
//	$color-flickr:							#FF0084;
//	$color-foursquare:						#0072B1;
//	$color-googleplus:						#DD4B39;
//	$color-github:							#4183C4;
//	$color-instagram:						#517FA4;
//	$color-kickstarter:						#76CC1E;
//	$color-lanyrd:							#152E51;
//	$color-lastfm:							#d51007;
//	$color-linkedin:						#007BB6;
//	$color-photodrop:						#CDB6E3;
//	$color-pinterest:						#CB2027;
//	$color-rdio:							#0088DA;
//	$color-quora:							#A82400;
//	$color-snapchat:						#FFFC00;
//	$color-soundcloud:						#FF6600;
//	$color-tumblr:							#32506D;
//	$color-twitter: 						#00ACED;
//	$color-vimeo:							#AAD450;
//	$color-vine:							#00BF8F;
//	$color-vk:								#45668E;
//	$color-youtube:							#BB0000;

	// default
	$project-default-color:					$color-dark-gray2;

// font family
$project-default-font-family-WEBSAFE-1:		Arial, Helvetica, sans-serif;

$project-default-font-family-1-REGULAR:		'Hurme-Geometric-Sans-No3-Regular', $project-default-font-family-WEBSAFE-1;
$project-default-font-family-1-BOLD:		'Hurme-Geometric-Sans-No3-Bold', $project-default-font-family-WEBSAFE-1;
$project-default-font-family-1-LIGHT:		'Hurme-Geometric-Sans-No3-Light', $project-default-font-family-WEBSAFE-1;

$project-default-font-family-2-BOLD:		'ChronicleDisplay-Bold', $project-default-font-family-WEBSAFE-1;

$project-default-font-family:				$project-default-font-family-1-LIGHT;

// font size
$project-default-font-size:					12px;

// height
$project-default-button-height:				48px;

// line height
$project-default-line-height-diff:			10px;
$project-default-line-height:				$project-default-font-size + $project-default-line-height-diff;

// margin
$project-default-margin:					0px 0px $project-default-line-height 0px;
$project-default-margin-bottom:				$project-default-line-height;

// opacity
$project-default-opacity-HOVER:				0.5;

// transitions
@mixin project-default-transition()			{ @include css3-transition(all, 0.5s, ease); } // USE THIS: @include project-default-transition();


/* ---- setup DEFAULTS END */



/* ---- create a LIST FROM DEAFULT COLORS START */

// list of colors for class generators
$colors: (

	// basic colors
	color-white:		$color-white,
    color-light-gray:	$color-light-gray,

	// social colors
//	color-bandcamp:		$color-bandcamp,
//	color-delicious:	$color-delicious,
//	color-facebook:		$color-facebook,
//	color-ficly:		$color-ficly,
//	color-flickr:		$color-flickr,
//	color-foursquare:	$color-foursquare,
//	color-googleplus:	$color-googleplus,
//	color-github:		$color-github,
//	color-instagram:	$color-instagram,
//	color-kickstarter:	$color-kickstarter,
//	color-lanyrd:		$color-lanyrd,
//	color-lastfm:		$color-lastfm,
//	color-linkedin:		$color-linkedin,
//	color-photodrop:	$color-photodrop,
//	color-pinterest:	$color-pinterest,
//	color-rdio:			$color-rdio,
//	color-quora:		$color-quora,
//	color-snapchat:		$color-snapchat,
//	color-soundcloud:	$color-soundcloud,
//	color-tumblr:		$color-tumblr,
//	color-twitter: 		$color-twitter,
//	color-vimeo:		$color-vimeo,
//	color-vine:			$color-vine,
//	color-vk:			$color-vk,
//	color-youtube:		$color-youtube
);
 
/* ---- create a LIST FROM DEAFULT COLORS END */
