// selectbox container
.sbHolder{
	@include css3-border-radius($project-form-control-border-radius);
	position: relative;
	background-color: $project-form-control-background-color;
	height: $project-form-control-height;
	line-height: $project-form-control-height;
	padding: $project-form-control-padding;
	padding-top: 0;
	padding-bottom: 0;

	// button
	.sbToggle{
		position: absolute;
		display: block;
		top: 0;
		right: 0px;
		width: $project-form-control-height;
		height: $project-form-control-height;
		line-height: $project-form-control-height;
		outline: none;
		border: none;
		background-color: transparent;
		background-image: url('../img/icons/select-skin-arrow-white.svg');
		background-position: center center;
		background-repeat: no-repeat;

		// mobile + tablet
		@media (max-width: $screen-md-max) {
			height: $project-form-control-height - 4;
		}

		html.desktop.osx &,
		html.iphone.safari &{
			height: $project-form-control-height - 4;
		}
/*

		&:before{
			content: "\f0d7";
			font-family: "FontAwesome";
			font-size: 20px;
			display: block;
			text-align: center;
		}
*/
	}

	// select
	a.sbSelector{
		@include css3-border-radius($project-form-control-border-radius);
		position: absolute;
		display: block;
		top: 0;
		left: 0;
		width: 100%;
		height: $project-form-control-height;
		line-height: $project-form-control-height;
		padding: 0 10px;
		border-color: $project-form-control-border-color;
		border-style: solid;
		border-width: $project-form-control-border-width;
		outline: none;
		overflow: hidden;
		font-family: $project-default-font-family;
		font-size: $project-form-control-font-size;
		color: $project-main-menu-item-color;
		text-align: left;

		&:hover{
			@include css3-opacity(1);
		}
	}

	&.sbToggleOpen a.sbSelector{
		@include css3-opacity(1);
		@include project-form-control-box-shadow-FOCUS();
		background-color: $color-gray6;
		border-color: $project-form-control-border-color-FOCUS;
		color: $project-main-menu-item-color;
	}

	// list
	.sbOptions{
		position: absolute;
		top: $project-form-control-height - 2 !important;
		left: 0px;
		width: 100%;
		margin: 0;
		padding: 0;
		z-index: 2;
//			overflow-y: auto !important;
		background-color: $color-gray6;
		border-color: $project-form-control-border-color-FOCUS;
		border-style: solid;
		border-width: $project-form-control-border-width;
		border-top: none;

		li{
			list-style: none;

			&:before{
				display: none;
			}

			a{
				display: block;
				padding: 10px 10px;
				line-height: $project-form-group-margin;
				outline: none;
				color: $project-form-control-color;

			}
		}
	}

	.sbSelector,
	.sbOptions a{
		font-family: $project-default-font-family;
		font-size: $project-form-control-font-size;
		color: $project-form-control-color;
		text-decoration: none;
		text-align: left;
	}
}

.lng-form{
	display: inline-block;

	// text
	.sbHolder{

		.sbSelector,
		.sbOptions li a{
			font-family: $project-main-menu-item-font-family;
			font-size: $project-main-menu-item-font-size;
			color: $project-main-menu-item-color;
			text-transform: uppercase;
		}
	}

	.sbHolder{
		position: relative;
		display: inline-block;
		width: 65px !important;
		vertical-align: middle;
		border: 0 !important;
		padding: 0;
		background-color: transparent;

		.sbSelector{
			@include css3-border-radius(0);
			font-style: normal;
			background-color: transparent;
			border: 0 !important;
			text-transform: uppercase;
		}

		.sbToggle{
			z-index: 1;
			background-size: 10px auto;;
		}

		.sbOptions{
			border: 0 !important;
		}
	}
}