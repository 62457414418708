/*** Project pagination ***/

/* pagination START */

// main
.main {

	.pagination {
		@include css3-border-radius($project-pagination-border-radius);
		margin: 0 0 $project-pagination-margin 0;
		vertical-align: top;

		> li {

			&:before {
				display: none;
			}

			> a {
				background: $project-pagination-background-color;
				border-color: $project-pagination-border-color;
				border-style: solid;
				border-width: $project-pagination-border-width;
				color: $project-pagination-color;
				font-family: $project-pagination-font-family;
				font-size: $project-pagination-font-size;
				height: auto;
				line-height: $project-pagination-line-height;
				padding: $project-pagination-padding;
				text-decoration: none;

				// desktop
				@media (min-width: $screen-lg-min) {
					&:hover,
					&:focus {
						@include css3-opacity($project-pagination-opacity-HOVER);
						background: $project-pagination-background-color-HOVER;
						border-color: $project-pagination-border-color-HOVER;
						color: $project-pagination-color-HOVER;
						text-decoration: none;
					}
				}
			}

			// previous / next
			&:first-child,
			&:last-child {
				> a {
					font-family: $project-default-font-family-WEBSAFE-1;
					background: $project-pagination-background-color-PAGER;
					border-color: $project-pagination-border-color-PAGER;
					color: $project-pagination-color-PAGER;

					// desktop
					@media (min-width: $screen-lg-min) {
						&:hover,
						&:focus {
							background: $project-pagination-background-color-PAGER-HOVER;
							border-color: $project-pagination-border-color-PAGER-HOVER;
							color: $project-pagination-color-PAGER-HOVER;
						}
					}
				}
			}

			// previous
			&:first-child {
				> a {
					@include css3-border-radius-separate($project-pagination-border-radius, 0, 0, $project-pagination-border-radius);
				}
			}

			// next
			&:last-child {
				> a {
					@include css3-border-radius-separate(0, $project-pagination-border-radius, $project-pagination-border-radius, 0);
				}
			}

			// active
			&.active {

				> a {
					background: $project-pagination-background-color-ACTIVE;
					color: $project-pagination-color-ACTIVE;
					border-color: $project-pagination-border-color-ACTIVE;

					// desktop
					@media (min-width: $screen-lg-min) {
						&:hover,
						&:focus {
							background: $project-pagination-background-color-ACTIVE-HOVER;
							color: $project-pagination-color-ACTIVE-HOVER;
							border-color: $project-pagination-border-color-ACTIVE-HOVER;
						}
					}
				}
			}

			// disabled
			&.disabled {

				> a {
					background: $project-pagination-background-color-DISABLED !important;
					color: $project-pagination-color-DISABLED !important;
					border-color: $project-pagination-border-color-DISABLED;
				}
			}
		}

		// small
		&-sm {
			@include css3-border-radius($project-pagination-border-radius-SMALL);

			> li {

				> a {
					border-width: $project-pagination-border-width-SMALL;
					font-size: $project-pagination-font-size-SMALL;
					line-height: $project-pagination-line-height-SMALL;
					padding: $project-pagination-padding-SMALL;
				}
			}
		}

		// large
		&-lg {
			@include css3-border-radius($project-pagination-border-radius-LARGE);

			> li {

				> a {
					border-width: $project-pagination-border-width-LARGE;
					font-size: $project-pagination-font-size-LARGE;
					line-height: $project-pagination-line-height-LARGE;
					padding: $project-pagination-padding-LARGE;
				}
			}
		}
	}
}

/* pagination END */
