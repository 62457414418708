/*** Project buttons ***/

/* buttons START */

.main {

	.btn {
		@include css3-border-radius($project-button-border-radius);
		@include project-button-box-shadow();
		@include project-default-transition();
		background: $project-button-background-color;
		border-color: $project-button-border-color;
		border-width: $project-button-border-width;
		color: $project-button-color;
		font-family: $project-button-font-family;
		font-size: $project-button-font-size;
		font-weight: normal;
		height: $project-button-height;
		line-height: $project-button-line-height;
		margin: 0;
		padding: $project-button-padding;
		text-decoration: none;
		vertical-align: top;
		text-transform: uppercase;

		.idevice & {
			line-height: $project-button-line-height + 4;
		}

		// desktop
		@media (min-width: $screen-lg-min) {
			&:hover,
			&:focus {
				@include css3-opacity($project-button-opacity-HOVER);
				background: $project-button-background-color-HOVER;
				border-color: $project-button-border-color-HOVER;
				color: $project-button-color-HOVER;
				text-decoration: none !important;
			}
		}

		// small
		&.btn-sm {
			@include css3-border-radius($project-button-border-radius-SMALL);
			border-width: $project-button-border-width-SMALL;
			font-size: $project-button-font-size-SMALL;
			height: $project-button-height-SMALL;
			line-height: $project-button-line-height-SMALL;
			padding: $project-button-padding-SMALL;
		}

		// large
		&.btn-lg {
			@include css3-border-radius($project-button-border-radius-LARGE);
			border-width: $project-button-border-width-LARGE;
			font-size: $project-button-font-size-LARGE;
			height: $project-button-height-LARGE;
			line-height: $project-button-line-height-LARGE;
			padding: $project-button-padding-LARGE;
		}

		// disabled
		&[disabled] {
			@include css3-opacity(1);
			@include project-button-box-shadow-DISABLED();
			color: $project-button-color-DISABLED;
			background: $project-button-background-color-DISABLED;
			border-color: $project-button-border-color-DISABLED;
		}

		// version 1
		&.btn-version-1 {
			@include css3-border-radius($project-button-border-radius-version-1);
			@include project-button-box-shadow-version-1();
			background: $project-button-background-color-version-1;
			border-color: $project-button-border-color-version-1;
			border-width: $project-button-border-width-version-1;
			color: $project-button-color-version-1;
			font-family: $project-button-font-family-version-1;
			font-size: $project-button-font-size-version-1;
			height: $project-button-height-version-1;
			line-height: $project-button-line-height-version-1;
			padding: $project-button-padding-version-1;

			// desktop
			@media (min-width: $screen-lg-min) {
				&:hover,
				&:focus {
					@include css3-opacity($project-button-opacity-version-1-HOVER);
					background: $project-button-background-color-version-1-HOVER;
					border-color: $project-button-border-color-version-1-HOVER;
					color: $project-button-color-version-1-HOVER;
				}
			}

			// small
			&.btn-sm {
				@include css3-border-radius($project-button-border-radius-version-1-SMALL);
				border-width: $project-button-border-width-version-1-SMALL;
				font-size: $project-button-font-size-version-1-SMALL;
				height: $project-button-height-version-1-SMALL;
				line-height: $project-button-line-height-version-1-SMALL;
				padding: $project-button-padding-version-1-SMALL;
			}

			// large
			&.btn-lg {
				@include css3-border-radius($project-button-border-radius-version-1-LARGE);
				border-width: $project-button-border-width-version-1-LARGE;
				font-size: $project-button-font-size-version-1-LARGE;
				height: $project-button-height-version-1-LARGE;
				line-height: $project-button-line-height-version-1-LARGE;
				padding: $project-button-padding-version-1-LARGE;
			}
		}

			// disabled
			&[disabled] {

				&.btn-version-1 {
					@include project-button-box-shadow-version-1-DISABLED();
					color: $project-button-color-version-1-DISABLED;
					background: $project-button-background-color-version-1-DISABLED;
					border-color: $project-button-border-color-version-1-DISABLED;
				}
			}

		// options
		&.btn-success,
		&.btn-info,
		&.btn-warning,
		&.btn-danger,
		&.btn-link{
			@include css3-opacity(1);
			color: $project-button-option-color;

			// desktop
			@media (min-width: $screen-lg-min) {
				&:hover,
				&:focus {
					color: $project-button-option-color-HOVER;
				}
			}
		}

			// success
			&.btn-success {
				@include css3-background-opacity($project-button-option-background-color-SUCCESS, $project-button-option-background-opacity);
				border-color: $project-button-option-border-color-SUCCESS;

				// desktop
				@media (min-width: $screen-lg-min) {
					&:hover,
					&:focus {
						@include css3-background-opacity($project-button-option-background-color-SUCCESS, $project-button-option-background-opacity-HOVER);
					}
				}
			}

			// info
			&.btn-info {
				@include css3-background-opacity($project-button-option-background-color-INFO, $project-button-option-background-opacity);
				border-color: $project-button-option-border-color-INFO;

				// desktop
				@media (min-width: $screen-lg-min) {
					&:hover,
					&:focus {
						@include css3-background-opacity($project-button-option-background-color-INFO, $project-button-option-background-opacity-HOVER);
					}
				}
			}

			// warning
			&.btn-warning {
				@include css3-background-opacity($project-button-option-background-color-WARNING, $project-button-option-background-opacity);
				border-color: $project-button-option-border-color-WARNING;

				// desktop
				@media (min-width: $screen-lg-min) {
					&:hover,
					&:focus {
						@include css3-background-opacity($project-button-option-background-color-WARNING, $project-button-option-background-opacity-HOVER);
					}
				}
			}

			// danger
			&.btn-danger {
				@include css3-background-opacity($project-button-option-background-color-DANGER, $project-button-option-background-opacity);
				border-color: $project-button-option-border-color-DANGER;

				// desktop
				@media (min-width: $screen-lg-min) {
					&:hover,
					&:focus {
						@include css3-background-opacity($project-button-option-background-color-DANGER, $project-button-option-background-opacity-HOVER);
					}
				}
			}

			// link
			&.btn-link {
				background-color: transparent;
				border: 0;
				color: $project-button-option-color-LINK;

				// desktop
				@media (min-width: $screen-lg-min) {
					&:hover,
					&:focus {
						background-color: transparent;
						color: $project-button-option-color-LINK-HOVER;
					}
				}
			}
	}
}

/* buttons END */
